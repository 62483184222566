<template>
  <div id="credits">
    <CreditsBoard credit_title="Project Leads">
      <p>Ninjastahr</p>
      <p>bigchickennugget</p>
    </CreditsBoard>
    <CreditsBoard credit_title="Website Development">
      <p><a href="https://github.com/RayWarthog" target="_blank">Warthog</a></p>
    </CreditsBoard>
    <CreditsBoard credit_title="Contributors">
      <p>BaKamen</p>
      <p>Krim</p>
      <p>Lynn</p>
      <p>NoNein</p>
    </CreditsBoard>
    <CreditsBoard credit_title="Embed art">
      <p><a href="https://twitter.com/6InchSenpai" target="_blank">Julius @6InchSenpai</a></p>
    </CreditsBoard>
    <CreditsBoard credit_title="Drawpile">
      <div class="board-note">by the Hololive Fan Discord server</div>
      <div class="canvas-credit">
        <div class="canvas-credit-title">Organizer/Maintainer</div>
        <div>
          <p>rice#1789</p>
        </div>
      </div>
      <div class="canvas-credits-container">
        <div class="canvas-credit">
          <div class="canvas-credit-title">Canvas 1</div>
          <div>
            <p>shumai#6969</p>
            <p>Pdeo#2005</p>
            <p>Knockoff Red#1061</p>
            <p>Nekomaster9000#2563</p>
            <p>siyuki7#8181</p>
            <p>justahumanweeb#3232</p>
            <p>Lupin#6944</p>
            <p>Unique Reisen#6702</p>
            <p>chan-chan#4247</p>
            <p>Portgas</p>
            <p>Downvote#3153</p>
            <p>NemoPearwall#3441</p>
            <p>Wikt#2137</p>
            <p>Feron (Nyctea)</p>
            <p>ubiquitous#2282</p>
            <p>Rav 눈_눈#6066</p>
            <p>She wants the DDD#3291</p>
            <p>NiN_Fake#8235</p>
            <p>Girinka#0746</p>
            <p>@marisamarisa96</p>
          </div>
        </div>
        <div class="canvas-credit">
          <div class="canvas-credit-title">Canvas 2</div>
          <div>
            <p>Siyuki7#8181</p>
            <p>drunk as i please#0990</p>
            <p>AverageAsian#8469</p>
            <p>Cocoa Cawfee#6018</p>
            <p>Pdeo#2005</p>
            <p>bear#5416</p>
            <p>に-ni#2587</p>
            <p>gimmiepie#0790</p>
            <p>Yusei Fudo102(桐生会FOREVER)#5109</p>
            <p>Sunna#0001</p>
            <p>☼ 空のアホ#0069</p>
            <p>Edwin#4828</p>
            <p>bicky#0069</p>
            <p>itsyaboi_kroggu#9513</p>
            <p>enderlord#8020</p>
            <p>Rocket Devil#1585</p>
            <p>klevly#2555</p>
            <p>HEHEHEHEHEH#499</p>
            <p>Phoenix#4329</p>
          </div>
        </div>
        <div class="canvas-credit">
          <div class="canvas-credit-title">Canvas 3</div>
          <div>
            <p>pinxsemburat#4017</p>
            <p>月陽炎寺 Getsuyo Honodera#6666</p>
            <p>ProbablyNotArry#5907</p>
            <p>MegGal#6739</p>
            <p>Mr. Rogers#2988</p>
            <p>Ice-R#2822</p>
            <p>wyzcracks#4124</p>
            <p>PinPoint#1704</p>
            <p>SatisfiedSky#2508</p>
            <p>EliGabRet#5224</p>
            <p>Pdeo#2005</p>
            <p>YUSH#8469</p>
            <p>tastelikenyan#1687</p>
            <p>Lynn#0572</p>
          </div>
        </div>
        <div class="canvas-credit">
          <div class="canvas-credit-title">Canvas 4</div>
          <div>
            <p>SleepySogcks#5516</p>
            <p>Foxp#2434</p>
            <p>Fidel Catto#3500</p>
            <p>★Umbrilla★#2686</p>
            <p>MarioSonicLife</p>
            <p>IsoHrl#8803</p>
            <p>jinx's zed#6969</p>
            <p>izumi#4200</p>
            <p>tacticaldragon57#5472</p>
            <p>InstantRiot#2389/@InstantDoodles1</p>
            <p>jin_rusaki#3999</p>
            <p>ayenull#6969</p>
            <p>Urcier#1234</p>
            <p>Farelle#4525</p>
          </div>
        </div>
      </div>
    </CreditsBoard>
    <CreditsBoard credit_title="Shiny Smily Story (EN) Cover">
      <div class="board-note">by the Hololive Fan Discord Server</div>
      <div class="canvas-credit">
        <div class="canvas-credit-title">Mixing and Video</div>
        <div>
          <p>Regent (@reg_546)</p>
        </div>
      </div>
      <div class="canvas-credit-title">Vocals</div>
      <div class="canvas-credits-container">
        <div class="canvas-credit">
          <div>
            <p>klevly</p>
            <p>De_Solrac</p>
            <p>Hardtgang</p>
            <p>TheBitStick</p>
            <p>Kaizeshu</p>
            <p>《Commissar☆Egâlité》</p>
            <p>CorvusFall</p>
            <p>GoodBoyChend</p>
            <p>MINICUBANT</p>
            <p>Ni-Ni</p>
            <p>Tomura</p>
            <p>Julius Caёsaя</p>
            <p>RushカラーP</p>
            <p>ChaoticIdiot</p>
            <p>jinx's zed</p>
            <p>JUNO</p>
          </div>
        </div>
        <div class="canvas-credit">
          <div>
            <p>FongSifu</p>
            <p>jin_rusaki</p>
            <p>ZoZoGuy</p>
            <p>Code Machinima (MarioLuigi)</p>
            <p>drunk as i please</p>
            <p>mute-tation</p>
            <p>Madekuji-san</p>
            <p>芋洗坂ボリス</p>
            <p>Mochi</p>
            <p>りき丸</p>
            <p>Mango</p>
            <p>日向のじろきょー/Hyuga no Jirokyo</p>
            <p>Azrien Stewart</p>
            <p>カルマ</p>
            <p>SoyMilk</p>
          </div>
        </div>
      </div>
    </CreditsBoard>
    <CreditsBoard credit_title="Operation Magic Dragon">
      <div class="board-note">by the Kiryu Coco Fan Discord Server</div>
      <div class="participants">
        <div class="participants-partial">
          <p>@AngelTail2</p>
          <p>@PlatinumKn1ght</p>
          <p>@Setsuko_channel</p>
          <p>ざんこく くろ</p>
          <p>Pyromann</p>
          <p>Shimoren</p>
          <p>@sp_Dai75n</p>
          <p>@uyauyajiro</p>
          <p>@Dondon714532</p>
          <p>@MisakeTsuchimi</p>
          <p>@laihw06</p>
          <p>@MikeCall0421</p>
          <p>@vating449</p>
          <p>@Fliesenleger21</p>
          <p>@Rizeus_V</p>
          <p>@kate38017027</p>
          <p>@Archdarck</p>
          <p>KillerNoob69</p>
          <p>Essaix</p>
          <p>FlowerBomber</p>
        </div>
        <div class="participants-partial">
          <p>@macielpanqueque</p>
          <p>@patagonia816</p>
          <p>@Matiasja5</p>
          <p>@ottoyuen1</p>
          <p>@Saijou_Box</p>
          <p>@fan_kiryu</p>
          <p>@migizzz2</p>
          <p>@Lypricorn </p>
          <p>@alexis7182</p>
          <p>@r_shen01</p>
          <p>@MomonKusa</p>
          <p>@Shinkeiryu</p>
          <p>@xDinoflagellate</p>
          <p>@ouchihitme</p>
          <p>@KafuXYuka</p>
          <p>@knorrence</p>
          <p>@akibasa</p>
          <p>@ftltang2012</p>
        </div>
      </div>
    </CreditsBoard>
    <CreditsBoard credit_title="Special Thanks">
      <p>Kiryu Coco Fan Discord Server</p>
      <p>Hololive Fan Discord</p>
      <p>Hololive English Fan Server (HEFS)</p>
      <p><a href="https://hololive.wiki/wiki/Fan_Discords" target="_blank">And the other Hololive fan Discord servers</a></p>
    </CreditsBoard>
  </div>
</template>

<script>

import CreditsBoard from '@/components/CreditsBoard.vue'

import Masonry from 'masonry-layout'

export default {
  components: {
    CreditsBoard
  },
  methods: {
    initMasonry () {
      this.masonry = new Masonry(
        document.querySelector('#credits'),
        {
          itemSelector: '.credits-board',
          columnWidth: '.credits-board',
          gutter: 15
        }
      )
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.initMasonry()
      window.addEventListener('load', () => {
        if (this.masonry) {
          this.masonry.layout()
        }
      })
    })
  }
}
</script>

<style scoped>
.board-note {
  text-align: right;
  font-weight: bold;
}

.credits-board {
  margin-bottom: 15px;
}

.credit-names a {
  text-decoration: none;
  color: #36B5F5;
}

.canvas-credits-container, .participants {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.participants-partial {
  padding-right: 10px;
}

.canvas-credit-title {
  font-weight: bold;
}

.canvas-credit {
  margin-top: 5px;
  padding-right: 10px;
}

p {
  margin: 0;
}

@media (min-width:801px)  {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  #credits {
    padding-left: 15px;
    padding-right: 15px;
  }
}
</style>
